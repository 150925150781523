import colors from 'resources/constants/colors';
import styled from 'styled-components';

export const Background = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: ${colors.bg_base};
`;

export const Container = styled.div`
    padding: 32px 10px 0 10px;

    @media( min-width: 768px ){
        padding-left: 10%;
        padding-right: 10%;
    }
`;

export const CardContainer = styled.div`
    padding: 40px 20px;
    background-color: white;
    border-radius: 10px;
    margin-top: 40px;

    @media( min-width: 768px ){
        padding-left: 60px;
        padding-right: 60px;
        margin-top: 0px;
    }
`;

export const Header = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
`;

export const Logo = styled.img`
    width: 120px;
`;

export const Title = styled.h1`
    font-size: 2.4rem;
    font-weight: 400;
    margin: 0 auto 16px 0;
`;

export const Subtitle = styled.h2`
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0 auto 41px 0;
`;

export const Error = styled.p`
    font-size: 1.2rem;
    font-weight: 400;
    color: red;
`;

export const CheckboxContainer = styled.label`
    font-size: 1.6rem;
    font-weight: 400;
    height: 27px;
    display: flex;
    position: relative;
    user-select: none;
    align-items: center;
    padding-left: 35px;
    cursor: pointer;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        border-radius: 5px;
        border: #4990e2 1px solid;
    }
    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
        transform: rotate(45deg);
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
    }
    input:checked ~ .checkmark:after {
        display: block;
    }
    input:checked ~ .checkmark {
        background-color: #2196f3;
    }
`;
