import React from 'react';
import { Switch, Route } from "react-router-dom";
import Home from "pages/home";
import Auth from "pages/auth";

const App = () => {
  return (
    <Switch>
      <Route exact={true} path="/" component={Home} />
      <Route exact={true} path="/auth" component={Auth} />
    </Switch>
  );
}

export default App;
