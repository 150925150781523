const EN = {
    "TITLE": "Welcome!",
    "SUBTITLE" : "Please insert the data required.",

    "USERNAME" : "Username",
    "PASSWORD" : "Password",

    "REMEMBER_ME" : "Remember Me",
    "LOGIN" : "Login",

    "Incorrect user or password" : "Incorrect user or password",
}

export default EN;