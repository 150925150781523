import React, { useContext } from "react";
import CustomInput from "../components/Input";
import PrimaryButton from "@etiquette-ui/buttons";
import {
    Background,
    Container,
    Header,
    Logo,
    CardContainer,
    Title,
    Subtitle,
    Error,
    CheckboxContainer,
} from "components/styles";
import { I18nContext } from "i18n";
import LangSelect from "components/LangSelect";
import styled from "styled-components";

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    
    div {
        flex-grow: 1;
        flex-basis: 250px;
        max-width: 250px;
    }
`;

const LoginLayout = ({ form, logo, handleSubmit, handleChange, remember, handleRemember }) => {
    const { translate } = useContext(I18nContext);

    return (
        <Background>
            <Container>
                <Header>
                    <LangSelect />
                </Header>
                <div
                    style={{
                        padding: "20px",
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "30px",
                    }}
                >
                    <Logo src={logo} alt="logo" />
                </div>
                <CardContainer>
                    <Title>{translate("TITLE")}</Title>
                    <Subtitle>{translate("SUBTITLE")}</Subtitle>
                    <form onSubmit={handleSubmit}>
                        <CustomInput
                            name={"user"}
                            title={translate("USERNAME")}
                            value={form.user}
                            onChange={handleChange}
                        />
                        <div style={{ height: "24px" }}></div>
                        <CustomInput
                            name={"password"}
                            title={translate("PASSWORD")}
                            value={form.password}
                            onChange={handleChange}
                            hidden={true}
                        />
                        {form.isError && <Error>{translate("Incorrect user or password")}</Error>}
                        <div style={{ height: "40px" }}></div>
                        <CheckboxContainer>
                            <input
                                name={"remember"}
                                type="checkbox"
                                onChange={handleRemember}
                                checked={remember}
                            />
                            <span className="checkmark"></span>
                            {translate("REMEMBER_ME")}
                        </CheckboxContainer>
                        <div style={{ height: "24px" }}></div>
                        <ButtonContainer>
                            <PrimaryButton disabled={false} type="submit">
                                {translate("LOGIN")}
                            </PrimaryButton>
                        </ButtonContainer>
                    </form>
                </CardContainer>
            </Container>
        </Background>
    );
};

export default LoginLayout;
