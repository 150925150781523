import React, { useState } from 'react';
import colors from 'resources/constants/colors';
import styled from 'styled-components';
import EyeVisible from 'resources/img/eye-icon.svg';
import EyeNotVisible from 'resources/img/eyeclosed-icon.svg';

const InputContainer = styled.div`
    position: relative;
    background-color: ${colors.bg_grey};
    border-radius: 8px;
    padding: 8px;
    display: grid;
    grid-template-columns: auto 32px;
`;

const InputStyled = styled.input`
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    height: 32px;
    touch-action: none;
`;

const Title = styled.p`
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
    margin-bottom: 5px;
`;

const VisibilityContainer = styled.div`
    display: flex;
    width: 32px;
    pointer-events: none;
`;

const VisibilityToggle = styled.img`
    position: relative;
    margin: auto 0;
    width: 32px;
    cursor: pointer;
    pointer-events: all;
`;

const CustomInput = ({
    name,
    title,
    value,
    onChange,
    type = 'text',
    hidden = false,
}) => {
    const [visible, setVisible] = useState(false);
    return (
        <div>
            <Title>{title}</Title>
            <InputContainer>
                <InputStyled
                    name={name}
                    type={hidden ? (visible ? type : 'password') : type}
                    value={value}
                    onChange={onChange}
                />
                {hidden && (
                    <VisibilityContainer>
                        <VisibilityToggle
                            src={visible ? EyeNotVisible : EyeVisible}
                            alt="visibility"
                            onClick={() => setVisible(!visible)}
                        />
                    </VisibilityContainer>
                )}
            </InputContainer>
        </div>
    );
};

export default CustomInput;
