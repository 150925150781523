import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { authAPI } from 'resources/api';
import LoginLayout from './containers/LoginLayout';
import ALLOWED_DOMAINS from 'resources/constants/allowedDomains';

//Logos
import DefaultLogo from 'resources/img/logos/quince.svg';

const Home = () => {
  const [isFecthing, setIsFetching] = useState(false);
  const [remember, setRemember] = useState(false);
  const [form, setForm] = useState({
    user: '',
    password: '',
    isError: false,
  });
  const [origin, setOrigin] = useState({ domain: '', appId: '' });

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFetching(true);

    try {
      const toSubmit = { ...form };
      if (!!origin.appId && origin.appId !== 'undefined') toSubmit.app = origin.appId;

      const res = await authAPI.login(toSubmit);
      window.parent.postMessage(`data:${JSON.stringify(res)}|${remember}`, origin.domain);
    } catch (e) {
      console.error(e);
      setForm({
        ...form,
        isError: true,
      });
    }

    setIsFetching(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setForm({
      ...form,
      [e.target.name]: value,
      isError: false,
    });
  };

  const handleRemember = () => {
    setRemember(!remember);
  };

  const logo = () => {
    return DefaultLogo;
  };

  window.addEventListener(
    'message',
    (event) => {
      console.log('event', event);
      if (!ALLOWED_DOMAINS.includes(event.origin)) {
        console.log('no-origin');
        history.push('auth');
        return;
      }

      if (typeof event.data !== 'string') {
        console.log('data is not string');
        return;
      }

      if (!event.data.startsWith('getData')) {
        console.log('no-get-data');
        return;
      }

      const appId = event.data.slice(8);
      setOrigin({ domain: event.origin, appId });
    },
    false
  );

  return (
    <>
      {isFecthing && <div>Cargando...</div>}
      <LoginLayout
        form={form}
        logo={logo()}
        remember={remember}
        handleRemember={handleRemember}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
      />
    </>
  );
};

export default Home;
