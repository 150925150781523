import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import langs from 'i18n/langs';
import { I18nContext } from 'i18n';
import Icon from '@etiquette-ui/icons';
import { TEXT, SECONDARY_DARK } from '@etiquette-ui/colors';

const Container = styled.div`
    position: relative;
    z-index: 5;
`;

const Toggle = styled.div`
    display: flex;
    height: 25px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    cursor: pointer;
`;

const Flag = styled.img`
    width: 25px;
    user-select: none;
`;

const Country = styled.span`
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
    flex: 1;
    user-select: none;
    color: white;
    text-align: center;
`;

const LangList = styled.div`
    background-color: white;
    border-radius: 8px;
    position: absolute;
    top: 30px;
    right: 0;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-shadow: 0px 8px 24px #0332693D;
`;

const LangOption = styled.div`
    display: flex;
    gap: 10px;
    cursor: pointer;
    padding: 5px;
    border-radius: 8px;
    ${({ selected }) => (selected ? `background-color: ${SECONDARY_DARK} ;` : '')};
    &:hover {
        background-color: ${ SECONDARY_DARK };
    }
`;

const CountryOption = styled(Country)`
    color: ${TEXT};
`;

const LangSelect = () => {
    const { selectedLang, setSelectedLang } = useContext(I18nContext);
    const [visible, setVisible] = useState(false);

    const changeSelection = (index) => {
        setVisible(false);
        setSelectedLang(index);
    };

    return (
        <Container>
            <Toggle onClick={() => setVisible(!visible)}>
                <Flag src={langs[selectedLang].flag} alt="flag" />
                <Country>{langs[selectedLang].shortname.toUpperCase()}</Country>
                <Icon
                    icon={visible ? 'chevron-up' : 'chevron-down'}
                    styles={{
                        svg: { width: '20px' },
                        line: {
                            fill: 'none',
                            strokeLinecap: 'round',
                            strokeLinejoin: 'round',
                            strokeWidth: '2px',
                            stroke: 'white',
                        },
                    }}
                />
            </Toggle>
            {visible && (
                <LangList>
                    {langs.map((lang, i) => (
                        <LangOption key={i} selected={i === selectedLang} onClick={()=>changeSelection(i)}>
                            <Flag src={lang.flag} alt={`flag-${i}`} />
                            <CountryOption>{lang.name}</CountryOption>
                        </LangOption>
                    ))}
                </LangList>
            )}
        </Container>
    );
};

export default LangSelect;
