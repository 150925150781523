const BASE_DOMAIN = 'appsinti.com';

const API = process.env.REACT_APP_API_URL;

const IMAGE_URL = `${process.env.REACT_APP_API_URL}images/`;

export {
  BASE_DOMAIN,
  API,
  IMAGE_URL,
}