const ES = {
    "TITLE": "¡Bienvenido!",
    "SUBTITLE" : "Por favor ingresa los datos necesarios.",

    "USERNAME" : "Nombre de usuario",
    "PASSWORD" : "Contraseña",

    "REMEMBER_ME" : "Recuérdame",
    "LOGIN" : "Acceder",

    "Incorrect user or password" : "Usuario o contraseña incorrecta",
}

export default ES;