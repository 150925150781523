import Axios from "axios";

const CustomCrudder = (domain, resource) => {

  const url = `${domain}/${resource}`;

  const headers = {};

  return {
    login: async (data) => {
      try {
          const response = await Axios.post(`${url}/login`, data, {
              headers,
          });
          return response.data;
      } catch (error) {
          throw error;
      }
    },
  };
}

export default CustomCrudder;