import React from "react";
import { Background, Container, Header, Logo, CardContainer, Title } from 'components/styles';
import DefaultLogo from 'resources/img/logos/quince.svg';

const Auth = () => {
  return <Background>
        <Container>
            <Header>
                <Logo src={DefaultLogo} alt='logo'/>
            </Header>
            <CardContainer>                
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <Title style={{textAlign: 'center', margin: '0'}}>AUTH ACCESS DENIED</Title>
                </div>
            </CardContainer>
        </Container>
    </Background>;
};

export default Auth;